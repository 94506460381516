<template>
    <div v-if="modelValue" class="fixed top-0 w-full h-full bg-black/50 z-[80] flex justify-center items-center">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white">
            <div class="px-7 py-6 flex justify-between items-center">
                <span class="text-xl font-bold"> {{ title || 'Select Employee' }} </span>
                <a href="javascript:void(0)" class="ml-20" @click="$emit('update:modelValue', false)">
                    <img src="@/assets/img/app-popup-1.svg" alt="app-popup-1" />
                </a>
            </div>
            <div class="w-full h-px bg-gray-200"></div>

            <div class="flex justify-between items-center gap-5 flex-wrap p-5">
                <div class="w-full flex justify-between items-center gap-5 flex-wrap">
                    <div class="flex gap-5">
                        <ElementsSelect
                            v-if="showWorkScheduleOption"
                            v-model="searchJsonQuery.workScheduleSeq"
                            :options="workScheduleOptions"
                            :width60="true"
                            placeholder="All Work Schedule"
                            :useDefaultMsg="true"
                            @change="searchEmployeeData" />
                        <ElementsSelect
                            v-if="showDepartmentOption"
                            v-model="searchJsonQuery.departmentSeq"
                            :options="departmentOptions"
                            :width60="true"
                            placeholder="All Departments"
                            :useDefaultMsg="true"
                            @change="searchEmployeeData" />
                        <ElementsSelect
                            v-if="showJobTitleOption"
                            v-model="searchJsonQuery.jobTitleSeq"
                            :options="jobTitleOptions"
                            :width60="true"
                            placeholder="All Tiers"
                            :useDefaultMsg="true"
                            @change="searchEmployeeData" />
                    </div>
                    <div class="ml-auto">
                        <ElementsSearchBar
                            v-model="searchInputString"
                            :options="employeeOptions"
                            :height12="true"
                            :width60="true"
                            :placeholder="'Search Employees'"
                            @searchEvent="searchEmployeeData"
                            ref="employeeSearchBar"
                            :optionClickEvent="true"
                            :hasSearchButton="true" />
                    </div>
                </div>
            </div>

            <div class="flex max-h-[60vh] overflow-auto justify-between gap-4 border-y border-[#D6DDEB]">
                <div class="flex-grow w-[50%]">
                    <div class="min-h-[46.75rem] border-x border-b overflow-auto">
                        <div class="h-12 flex justify-start gap-4 items-center border-b px-4">
                            <input v-model="allCheck.isChecked" :id="allCheck.value" type="checkbox" class="h-5 p-3 w-5 rounded border-gray-300 focus:ring-indigo-500" @input="clickAllCheckbox" />
                            <p>Select All</p>
                        </div>
                        <div class="max-h-[42.5rem]">
                            <div class="min-w-full">
                                <div v-for="(employee, index) in filteredEmployeeList" v-bind:key="index">
                                    <div class="h-18 flex justify-start items-center px-1 py-2.5 w-full" :class="{ 'border-b': index !== filteredEmployeeList.length - 1 }">
                                        <div class="w-[3.125rem] text-sm font-normal text-center truncate">
                                            <input v-model="employee.isChecked" :id="allCheck.value" type="checkbox" class="h-5 p-3 w-5 rounded border-gray-300 focus:ring-indigo-500" />
                                        </div>

                                        <div class="w-[3.125rem] text-sm font-normal text-center truncate">
                                            {{ searchJsonQuery.offset + index + 1 }}
                                        </div>

                                        <div class="w-[20rem] flex items-center gap-2 px-4">
                                            <StatusCircle :status-code="employee.employeeStatus" />
                                            <div class="ml-1 flex-1 text-base font-bold flex flex-col">
                                                <p>
                                                    {{ employee.employeeName }}
                                                </p>
                                                <p class="font-normal">
                                                    {{ employee.departmentName }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full h-28 flex justify-center items-center">
                        <ElementsPagination v-model="currentPage" :totalContent="employeeCount" :contentsPerPage="searchJsonQuery.limit" @clickPage="afterClickPage" />
                    </div>
                </div>

                <div class="flex-grow w-[5%] flex justify-center items-center">
                    <div
                        @click="selectEmployee"
                        class="w-[2rem] h-[2rem] border-[#EAECF9] border-[1.2px] rounded-lg hover:border-none hover:bg-[#F1F2F9] flex justify-center items-center">
                        <svg class="cursor-pointer" width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2294 10.5053C10.2294 10.2931 10.1451 10.0897 9.99508 9.93964L4.99508 4.93964C4.68266 4.62722 4.17613 4.62722 3.86371 4.93964C3.55129 5.25206 3.55129 5.75859 3.86371 6.07101L8.29802 10.5053L3.86371 14.9396C3.55129 15.2521 3.55129 15.7586 3.86371 16.071C4.17613 16.3834 4.68266 16.3834 4.99508 16.071L9.99508 11.071C10.1451 10.921 10.2294 10.7175 10.2294 10.5053Z" fill="#4261EE"/>
                            <path d="M11.0963 5.50537L16.0963 10.5054L11.0963 15.5054" stroke="#4261EE" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>

                <div class="flex-grow w-[45%] pr-4 py-8">
                    <div class="h-[46.75rem] border-[#D6DDEB] border-[1px] rounded-lg overflow-hidden">
                        <div class="h-[4.25rem] flex justify-between items-center border-b-[1px] px-4">
                            <p>Selected ({{ selectedEmployeeList.length }})</p>
                            <p class="text-[#F40009] text-[12px] cursor-pointer" @click="deleteEmployee('ALL')">Delete All</p>
                        </div>
                        <div class="overflow-auto max-h-[42.5rem]">
                            <div class="min-w-full">
                                <template v-for="(employee, index) in selectedEmployeeList" v-bind:key="index">
                                    <div class="flex justify-between items-center px-4 py-2.5 w-full">
                                        <div class="w-[20rem] flex items-center gap-2 px-4">
                                            <StatusCircle :status-code="employee.employeeStatus" />
                                            <div class="flex-1 text-base font-bold flex flex-col">
                                                <p>
                                                    {{ employee.employeeName }}
                                                </p>
                                                <p class="font-normal">
                                                    {{ employee.departmentName }}
                                                </p>
                                            </div>
                                        </div>
                                        <button class="w-[5%] flex justify-center items-center" @click="deleteEmployee(employee)">
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.66003 8.00016L8.00003 1.66016" stroke="#AAB0BA" stroke-width="1.68021" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.00003 8.00016L1.66003 1.66016" stroke="#AAB0BA" stroke-width="1.68021" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-h-[70vh]">
                <div class="px-4 py-6 flex justify-end gap-2">
                    <button
                        class="w-28 h-12 bg-white rounded flex items-center gap-2 justify-center text-gray-500 font-semibold text-sm border border-gray-300"
                        type="button"
                        @click="$emit('update:modelValue', false)">
                        Cancel
                    </button>
                    <button class="w-28 h-12 bg-indigo-600 rounded flex items-center gap-2 justify-center text-white font-semibold text-sm" type="submit" @click="saveEmployee">Add</button>
                </div>
            </div>
        </div>
        <Teleport to="body">
            <ConfirmPopup v-model="showWithoutSelectPopup" confirmDescription="Please select employee(s) before proceeding." buttonText="OK"></ConfirmPopup>
        </Teleport>
    </div>
</template>

<script>
import ElementsSelect from '@/components/elements/ElementsSelect.vue';
import ElementsSearchBar from '@/components/elements/ElementsSearchBar.vue';
import ElementsPagination from '@/components/elements/ElementsPagination.vue';
import StatusCircle from '@/components/employee/status-circle/status-circle';
import ConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
    components: {
        ElementsSelect,
        ElementsSearchBar,
        ElementsPagination,
        StatusCircle,
        ConfirmPopup,
    },
    props: {
        employeeList: Array,
        selectedList: Array,
        modelValue: Boolean,
        emptyOption: Boolean,
        title: String,
        showWorkScheduleOption: Boolean,
        showDepartmentOption: Boolean,
        showJobTitleOption: Boolean,
    },
    watch: {
        modelValue(newVal) {
            if (!newVal) {
                return;
            }

            const self = this;
            self.employeeOptions = [];
            self.workScheduleOptions = [];
            self.departmentOptions = [];
            self.jobTitleOptions = [];
            self.searchedText = null;
            self.searchedValues = null;
            self.searchJsonQuery = {
                limit: 10,
                offset: null,
                employeeSeqList: null,
                workScheduleSeq: null,
                departmentSeq: null,
                jobTitleSeq: null,
            };
            self.unSelectedEmployeeList = [...self.employeeList];
            self.unSelectedEmployeeList.forEach((employee) => (employee.isChecked = false));

            let selected = [];
            if (self.selectedList) {
                selected = self.unSelectedEmployeeList.filter((employee) => self.selectedList.includes(employee.employeeSeq));
                self.unSelectedEmployeeList = self.unSelectedEmployeeList.filter((employee) => !self.selectedList.includes(employee.employeeSeq));
            }

            self.employeeCount = self.unSelectedEmployeeList.length;

            self.filteredEmployeeList = self.unSelectedEmployeeList.slice(0, self.searchJsonQuery.limit);

            self.selectedEmployeeList = selected;
            self.allCheck.isChecked = false;

            self.getEmployeeOptions();
            self.getWorkScheduleOptions();
            self.getDepartmentOptions();
            self.getJobTitleOptions();
            self.currentPage = 1;
        },
    },
    emits: ['save-employee', 'update:modelValue'],
    mounted() {},
    data() {
        return {
            employeeOptions: [],
            workScheduleOptions: [],
            departmentOptions: [],
            jobTitleOptions: [],
            searchInputString: null,
            searchedText: null,
            searchedValues: null,
            searchJsonQuery: {
                limit: 10,
                offset: null,
                employeeSeqList: null,
                workScheduleSeq: null,
                departmentSeq: null,
                jobTitleSeq: null,
            },
            allCheck: {
                isChecked: false,
                text: '',
                value: 'ALL',
            },
            currentPage: null,
            employeeCount: null,

            unSelectedEmployeeList: [],
            filteredEmployeeList: [],
            selectedEmployeeList: [],
            showWithoutSelectPopup: false,
        };
    },
    methods: {
        getEmployeeOptions() {
            const self = this;

            self.employeeOptions = self.unSelectedEmployeeList.map((emp) => ({ text: emp.employeeName, value: emp.employeeSeq }));
        },
        getDepartmentOptions() {
            const self = this;

            const url = self.$api('uri', 'get-department');
            const json_query = JSON.stringify({
                showRootNode: null,
            });
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                const options = res.data.data.list.map((dep) => ({
                    text: dep.departmentName,
                    value: dep.departmentSeq,
                }));

                self.departmentOptions.push(...options);
            });
        },
        getJobTitleOptions() {
            const self = this;

            const json_query = JSON.stringify({
                limit: -1,
                offset: 0,
            });
            const url = self.$api('uri', 'get-jobTitle');
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                const options = res.data.data.list.map((title) => ({ text: title.jobTitleName, value: title.jobTitleSeq }));

                self.jobTitleOptions.push(...options);
            });
        },
        getWorkScheduleOptions() {
            const self = this;
            const url = self.$api('uri', 'get-workSchedule');
            self.$axios.get(url).then((res) => {
                self.workScheduleOptions = res.data.data.list.map((item) => ({
                    text: item.workScheduleName,
                    value: item.workScheduleSeq,
                }));
            });
        },
        searchEmployeeData() {
            const self = this;

            self.getEmployeesListData();
            self.currentPage = 1;
        },
        getEmployeesListData(offset = 0) {
            const self = this;
            self.allCheck.isChecked = false;
            self.searchJsonQuery.offset = offset;

            if (offset == 0) {
                self.searchedText = self.$refs.employeeSearchBar.getSearchedText();
                self.searchedValues = self.$refs.employeeSearchBar.getSearchedValues();
            } else {
                self.$refs.employeeSearchBar.setInputText(self.searchedText);
            }

            self.searchJsonQuery.employeeSeqList = self.searchedValues;

            self.unSelectedEmployeeList = self.employeeList.filter((employee) => !self.selectedEmployeeSeqList().includes(employee.employeeSeq));

            if (self.searchJsonQuery.departmentSeq == null && self.searchJsonQuery.workScheduleSeq == null
                && self.searchJsonQuery.jobTitleSeq == null && self.searchJsonQuery.employeeSeqList == null) {
                self.filteredEmployeeList = [...self.unSelectedEmployeeList];
            } else {
                self.filteredEmployeeList.splice(0, self.filteredEmployeeList.length);
                const employeeSeqSet = new Set(self.searchJsonQuery.employeeSeqList);

                self.filteredEmployeeList = self.unSelectedEmployeeList.filter(
                    (employee) => 
                    (self.searchJsonQuery.workScheduleSeq == null || employee.workScheduleSeq === self.searchJsonQuery.workScheduleSeq)
                    &&
                    (self.searchJsonQuery.departmentSeq == null || employee.departmentSeq === self.searchJsonQuery.departmentSeq)
                    &&
                    (self.searchJsonQuery.jobTitleSeq == null || employee.jobTitleSeq === self.searchJsonQuery.jobTitleSeq)
                    &&
                    (self.searchJsonQuery.employeeSeqList == null || employeeSeqSet.has(employee.employeeSeq))
                );
            }

            self.employeeCount = self.filteredEmployeeList.length;
            self.filteredEmployeeList = self.filteredEmployeeList.slice(offset, offset + self.searchJsonQuery.limit);
        },
        afterClickPage(offset) {
            const self = this;
            self.getEmployeesListData(offset);
        },
        clickAllCheckbox() {
            const self = this;
            self.allCheck.isChecked = !self.allCheck.isChecked;
            self.filteredEmployeeList.forEach((item) => {
                item.isChecked = self.allCheck.isChecked;
            });
        },
        selectedEmployeeSeqList() {
            const self = this;
            let seqList = [];

            if (self.selectedEmployeeList) {
                seqList = self.selectedEmployeeList.map((employee) => employee.employeeSeq);
            }

            return seqList;
        },
        selectEmployee() {
            const self = this;
            const selectedEmployeeSeqSet = new Set(self.selectedEmployeeList.map((employee) => employee.employeeSeq));

            self.filteredEmployeeList.forEach((employee) => {
                if (!selectedEmployeeSeqSet.has(employee.employeeSeq) && employee.isChecked) {
                    self.selectedEmployeeList.push(employee);
                    employee.isChecked = false;
                }
            });

            self.searchEmployeeData();
        },
        deleteEmployee(deleteEmployee) {
            const self = this;

            if (deleteEmployee === 'ALL') {
                self.selectedEmployeeList = [];
                self.searchEmployeeData();
                return;
            }
            self.selectedEmployeeList = self.selectedEmployeeList.filter((item) => item.employeeSeq !== deleteEmployee.employeeSeq);
            self.searchEmployeeData();
        },
        saveEmployee() {
            const self = this;
            if (!self.emptyOption && self.selectedEmployeeList.length < 1) {
                self.showWithoutSelectPopup = true;
                return;
            }
            self.$emit('save-employee', self.selectedEmployeeList);
        },
    },
};
</script>
