<script>
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { ValidateUtil } from '@/plugins/app-util.js';
import { inject } from 'vue';

export default {
    props: {
        modelValue: Boolean,
        name: String,
    },
    emits: ['closePopup', 'successInvite', 'update:modelValue'],
    data() {
        return {
            showErrorNotice: false,
            isEdit: false,
            notificationErrMSG: null,
            mainWorkSchedule: null,
            phoneNumberPrepend: '+63',
            departmentOptions: [],
            jobTitleOptions: [],
            workScheduleOptions: [],
            workPlaceOptions: [],
            salaryAmountTypeOptions: [
                { text: 'Annually', value: 'ANNUALLY' },
                { text: 'Monthly', value: 'MONTHLY' },
                { text: 'Weekly', value: 'WEEKLY' },
            ],
            taxCodeOptions: [
                { text: 'S', value: 'S' },
                { text: 'S1', value: 'S1' },
                { text: 'S2', value: 'S2' },
                { text: 'S3', value: 'S3' },
                { text: 'S4', value: 'S4' },
                { text: 'M', value: 'M' },
                { text: 'M1', value: 'M1' },
                { text: 'M2', value: 'M2' },
                { text: 'M3', value: 'M3' },
                { text: 'M4', value: 'M4' },
                { text: 'Z', value: 'Z' },
            ],
            requestErrorMsg: {
                firstName: "First Name can't be blank",
                lastName: "Last Name can't be blank",
                phoneNumber: 'Please enter a valid phone number. The number should have 7~20digits',
                inviteEmail: 'Please insert valid email address',
                duplicatedEmail: 'This address is already exist',
                joinDate: "Join Date can't be blank",
                workScheduleSeq: 'WorkSchedule is should be selected',
                salaryAmountType: 'Payment Type is should be selected',
                salaryAmount: 'Only numbers are allowed',
                taxCode: 'Tax Code is should be selected',
                tinNo: 'Only numbers are allowed, and please input it in a 12-digit format',
                sssNo: 'Only numbers are allowed, and please input it in a 10-digit format',
                pagIbig: 'Only numbers are allowed, and please input it in a 12-digit format',
                philhealth: 'Only numbers are allowed, and please input it in a 12-digit format',
                rdoCode: 'Please enter a number in a 3-digit format',
            },
            requestError: {
                firstName: null,
                lastName: null,
                phoneNumber: null,
                inviteEmail: null,
                joinDate: null,
                workScheduleSeq: null,
                salaryAmountType: null,
                salaryAmount: null,
                taxCode: null,
                tinNo: null,
                sssNo: null,
                pagIbig: null,
                philhealth: null,
                rdoCode: null,
            },
            request: {
                employeeInviteSeq: null,
                firstName: null,
                middleName: null,
                lastName: null,
                phoneNumber: null,
                inviteEmail: null,
                joinDate: moment().format('YYYYMMDD'),
                workPlaceSeq: null,
                departmentSeq: null,
                jobTitleSeq: null,
                workScheduleSeq: null,
                salaryAmountType: null,
                salaryAmount: null,
                taxCode: null,
                tinNo: null,
                socialSecuritySystem: null,
                pagIbig: null,
                philhealth: null,
                rdoCode: null,
                bankName: null,
                bankAccountNumber: null,
            },
            formattedTin: '',
            formattedSSS: '',
            formattedPhilhealth: '',
            formattedPagIbig: '',
        }; //return
    }, //data
    setup() {
        const { checkSubscription } = inject('appUtil');
        return { checkSubscription };
    },
    mounted() {
        const self = this;
        self.getInitData();
    },
    methods: {
        isValidCheck() {
            const self = this;
            let isValid = true;

            if (!ValidateUtil.checkIsEmail(self.request.inviteEmail)) {
                self.requestError.inviteEmail = self.requestErrorMsg.inviteEmail;
                isValid = false;
            }

            if (ValidateUtil.invalidPhoneNumber(self.request.phoneNumber)) {
                self.requestError.phoneNumber = self.requestErrorMsg.phoneNumber;
                isValid = false;
            }

            if (ValidateUtil.checkIsEmpty(self.request.firstName)) {
                self.requestError.firstName = self.requestErrorMsg.firstName;
                isValid = false;
            }

            if (ValidateUtil.checkIsEmpty(self.request.lastName)) {
                self.requestError.lastName = self.requestErrorMsg.lastName;
                isValid = false;
            }

            if (ValidateUtil.checkIsEmpty(self.request.joinDate)) {
                self.requestError.joinDate = self.requestErrorMsg.joinDate;
                isValid = false;
            }

            // flexben only 일 경우 여기서 검증 종료
            if (self.checkSubscription('FLEXBEN_ONLY_USAGE')) {
                return isValid;
            }

            if (!self.request.workScheduleSeq) {
                self.requestError.workScheduleSeq = self.requestErrorMsg.workScheduleSeq;
                isValid = false;
            }

            if (ValidateUtil.checkIsEmpty(self.request.salaryAmountType)) {
                self.requestError.salaryAmountType = self.requestErrorMsg.salaryAmountType;
                isValid = false;
            }

            if (self.request.salaryAmount == null || !ValidateUtil.checkIsNumber(self.request.salaryAmount)) {
                self.requestError.salaryAmount = self.requestErrorMsg.salaryAmount;
                isValid = false;
            }

            if (ValidateUtil.checkIsEmpty(self.request.taxCode)) {
                self.requestError.taxCode = self.requestErrorMsg.taxCode;
                isValid = false;
            }

            if (self.request.tinNo) {
                const tinNo = self.request.tinNo.replaceAll('-', '');
                if (!ValidateUtil.checkIsNumber(tinNo) || tinNo.length != 12) {
                    self.requestError.tinNo = self.requestErrorMsg.tinNo;
                    isValid = false;
                }
            }
            if (self.request.socialSecuritySystem) {
                const sssNo = self.request.socialSecuritySystem.replaceAll('-', '');
                if (!ValidateUtil.checkIsNumber(sssNo) || sssNo.length != 10) {
                    self.requestError.socialSecuritySystem = self.requestErrorMsg.socialSecuritySystem;
                    isValid = false;
                }
            }
            if (self.request.pagIbig) {
                const pagIbig = self.request.pagIbig.replaceAll('-', '');
                if (!ValidateUtil.checkIsNumber(pagIbig) || pagIbig.length != 12) {
                    self.requestError.pagIbig = self.requestErrorMsg.pagIbig;
                    isValid = false;
                }
            }
            if (self.request.philhealth) {
                const philhealth = self.request.philhealth.replaceAll('-', '');
                if (!ValidateUtil.checkIsNumber(philhealth) || philhealth.length != 12) {
                    self.requestError.philhealth = self.requestErrorMsg.philhealth;
                    isValid = false;
                }
            }
            if (self.request.rdoCode) {
                if (!ValidateUtil.checkIsNumber(self.request.rdoCode) || self.request.rdoCode.length != 3) {
                    self.requestError.rdoCode = self.requestErrorMsg.rdoCode;
                    isValid = false;
                }
            }
            return isValid;
        },
        resetErrorMsg() {
            const self = this;
            self.requestError = {
                firstName: null,
                lastName: null,
                phoneNumber: null,
                inviteEmail: null,
                joinDate: null,
                workScheduleSeq: null,
                salaryAmountType: null,
                salaryAmount: null,
                taxCode: null,
                tinNo: null,
                sssNo: null,
                pagIbig: null,
                philhealth: null,
                rdoCode: null,
            };
        },
        cancelInvite() {
            const self = this;
            self.$emit('closePopup');
        },
        resetReqeustData() {
            const self = this;
            self.request = {
                firstName: null,
                middleName: null,
                lastName: null,
                phoneNumber: null,
                inviteEmail: null,
                joinDate: moment().format('YYYYMMDD'),
                workPlaceSeq: null,
                departmentSeq: null,
                jobTitleSeq: null,
                workScheduleSeq: null,
            };
            self.request.workScheduleSeq = self.mainWorkSchedule;
        },
        getDepartmentOptions() {
            const self = this;

            const url = self.$api('uri', 'get-department');
            const json_query = JSON.stringify({
                showRootNode: null,
            });

            self.$axios.get(url, { params: { json_query } }).then((res) => {
                self.departmentOptions = res.data.data.list.map((dep) => ({
                    text: dep.departmentName,
                    value: dep.departmentSeq,
                }));
            });
        },
        getJobTitleOptions() {
            const self = this;

            const json_query = JSON.stringify({
                limit: -1,
                offset: 0,
            });

            let url = self.$api('uri', 'get-jobTitle');
            self.$axios
                .get(url, { params: { json_query } })
                .then((res) => {
                    self.jobTitleOptions = res.data.data.list.map((item) => ({ text: item.jobTitleName, value: item.jobTitleSeq }));
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getWorkPlaceOptions() {
            const self = this;
            const url = self.$api('uri', 'get-work-place');
            self.$axios
                .get(url)
                .then((res) => {
                    return res.data.data.list;
                })
                .then((workPlaceList) => {
                    self.workPlaceOptions = workPlaceList.map((place) => {
                        return { text: place.workPlaceName, value: place.workPlaceSeq };
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getWorkScheduleOptions() {
            const self = this;

            const url = self.$api('uri', 'get-workSchedule');
            self.$axios
                .get(url)
                .then((res) => {
                    self.workScheduleOptions = res.data.data.list.map((schedule) => {
                        if (schedule.mainWorkScheduleYn == 'Y') {
                            self.mainWorkSchedule = schedule.workScheduleSeq;
                        }
                        return {
                            text: schedule.workScheduleName,
                            value: schedule.workScheduleSeq,
                        };
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getInitData() {
            const self = this;
            self.getDepartmentOptions();
            self.getJobTitleOptions();
            self.getWorkPlaceOptions();
            self.getWorkScheduleOptions();
        },
        updateData(item) {
            const self = this;
            self.isEdit = true;
            const url = self.$api('uri', 'get-invite');
            self.$axios.get(`${url}/${encodeURIComponent(item.employeeInviteSeq)}`).then((res) => {
                self.request = { ...res.data.data };
                // self.request.phoneNumber = ValidateUtil.convertPhoneGlobalToLocal( res.data.data.phoneNumber );
                self.request.workScheduleSeq = self.request.workScheduleSeq || self.mainWorkSchedule;

                self.changeTinNumberFormat(self.request.tinNo);
                self.changeSSSNumberFormat(self.request.socialSecuritySystem);
                self.changePhilhealthFormat(self.request.philhealth);
                self.changePagIbigFormat(self.request.pagIbig);
            });
        },
        getDataForSave() {
            const self = this;

            let {
                firstName,
                middleName,
                lastName,
                phoneNumber,
                inviteEmail,
                joinDate,
                departmentSeq,
                jobTitleSeq,
                workScheduleSeq,
                workPlaceSeq,
                salaryAmountType,
                salaryAmount,
                taxCode,
                tinNo,
                socialSecuritySystem,
                pagIbig,
                philhealth,
                rdoCode,
                bankName,
                bankAccountNumber,
            } = self.request;

            phoneNumber = ValidateUtil.removeCountryCodePhoneNumber(phoneNumber);
            const employeeName = ValidateUtil.employeeNameSave(firstName, middleName, lastName);
            const saveItem = {
                employeeName,
                firstName,
                middleName,
                lastName,
                phoneNumber,
                joinDate,
                inviteEmail,
                departmentSeq,
                jobTitleSeq,
                workScheduleSeq,
                workPlaceSeq,
                salaryAmountType,
                salaryAmount,
                taxCode,
                tinNo,
                socialSecuritySystem,
                pagIbig,
                philhealth,
                rdoCode,
                bankName,
                bankAccountNumber,
            };

            for (let key in saveItem) {
                if (!saveItem[key]) continue;
                if (typeof saveItem[key].trim === 'function') {
                    saveItem[key] = saveItem[key].trim();
                }
            }

            return saveItem;
        },
        inviteEmployee() {
            const self = this;
            self.resetErrorMsg();

            if (!self.isValidCheck()) {
                // self.showErrorNotice = true;
                // self.notificationErrMSG = 'Please check the error message';
                return;
            }
            const saveItem = self.getDataForSave();
            const url = self.$api('uri', 'post-invite');
            self.$axios
                .post(url, saveItem)
                .then((res) => {
                    console.log('post res => ', res.data);
                    self.$emit('successInvite', 'It is successfully invited ');
                    self.$emit('closePopup');
                })
                .catch((err) => {
                    if (err.response.data.code == 'HR_INVITE_INSERT_ALREADY_USED_EMAIL_400_FAILED') {
                        self.requestError.inviteEmail = self.requestErrorMsg.duplicatedEmail;
                    }
                });
        },
        editAndResendEmployee() {
            const self = this;
            self.resetErrorMsg();
            if (!self.isValidCheck()) {
                // self.showErrorNotice = true;
                return;
            }

            const saveItem = self.getDataForSave();
            const url = self.$api('uri', 'put-invite');
            self.$axios
                .put(`${url}/${encodeURIComponent(self.request.employeeInviteSeq)}`, saveItem)
                .then((res) => {
                    console.log('resend', res);
                    self.$emit('successInvite', ' It is successfully resend invite email ');
                    self.$emit('closePopup');
                })
                .catch((err) => {
                    if (err.response.data.code == 'HR_INVITE_INSERT_ALREADY_USED_EMAIL_400_FAILED') {
                        self.requestError.inviteEmail = self.requestErrorMsg.duplicatedEmail;
                    }
                });
        },
        hidePopup() {
            const self = this;
            self.$emit('update:modelValue', false);
        },
        changeTinNumberFormat(input) {
            const self = this;

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                formattedValue = inputValue.match(new RegExp('.{1,3}', 'g')).join('-'); // xxx-xxx-xxx
                const regex = /^(\d{3})(\d{3})(\d{3})(\d{1,})$/;
                const match = regex.exec(inputValue);

                if (match) {
                    formattedValue = `${match[1]}-${match[2]}-${match[3]}-${match[4]}`; // xxx-xxx-xxxxx
                    self.formattedTin = formattedValue;
                }

                self.formattedTin = formattedValue;
            }

            self.request.tinNo = inputValue.replace(/\D/g, '');
        },
        changeSSSNumberFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [2, 7];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');

                self.formattedSSS = formattedValue;
            }

            self.request.socialSecuritySystem = inputValue.replace(/\D/g, '');
        },
        changePhilhealthFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [2, 9];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');

                self.formattedPhilhealth = formattedValue;
            }

            self.request.philhealth = inputValue.replace(/\D/g, '');
        },
        changePagIbigFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [4, 4];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');

                self.formattedPagIbig = formattedValue;
            }

            self.request.pagIbig = inputValue.replace(/\D/g, '');
        },
        checkDecimalNumber(event) {
            const self = this;
            const value = event.target.value;
            if (value == null || value == '') {
                return;
            }
            let decimalValue = value.replace(/[^0-9.]/g, ''); // 숫자와 소수점만 남김
            decimalValue = decimalValue.replace(/(\..*)\./g, '$1'); // 첫 번째 소수점 이후에 나오는 추가 소수점을 제거

            if (!Number.isNaN(decimalValue) && decimalValue !== '') {
                self.request.salaryAmount = Number.parseFloat(decimalValue).toFixed(2).toString();
            }
        },
    },
};
</script>

<template>
    <div v-show="modelValue" class="z-[80] fixed top-0 w-full h-full bg-black/50 flex justify-center items-center">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white">
            <div class="px-7 py-6 flex justify-between items-center">
                <h2 class="text-xl font-bold">{{ name }}</h2>
                <a href="javascript:void(0)" class="ml-20" @click="hidePopup">
                    <img src="@/assets/img/app-popup-1.svg" alt="app-popup-1" />
                </a>
            </div>

            <div class="w-full h-px bg-gray-200"></div>
            <div class="max-h-[70vh] min-w-[600px] overflow-auto">
                <div class="pr-4 pb-8 pl-4">
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">First Name<span>*</span></div>
                        <ElementsInput v-model="request.firstName" placeholder="Enter First Name" class="flex-1" :full="true" :error="requestError.firstName" :maxlength="60" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Middle Name Initial</div>
                        <ElementsInput v-model="request.middleName" placeholder="Enter Middle Name Initial" class="flex-1" :full="true" :maxlength="60" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Last Name<span>*</span></div>
                        <ElementsInput v-model="request.lastName" placeholder="Enter Last Name" class="flex-1" :full="true" :error="requestError.lastName" :maxlength="60" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Phone Number<span>*</span></div>
                        <div class="flex-1 flex justify-between items-center gap-3.5">
                            <ElementsInput v-model="phoneNumberPrepend" :width14="true" :disabled="true" />
                            <ElementsInput v-model="request.phoneNumber" placeholder="Enter Phone Number" class="flex-1" :full="true" :error="requestError.phoneNumber" :maxlength="16" />
                        </div>
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Work Email<span>*</span></div>
                        <ElementsInput v-model="request.inviteEmail" placeholder="Enter Work Email" class="flex-1" :full="true" :error="requestError.inviteEmail" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Join Date<span>*</span></div>
                        <ElementsDatePicker v-model="request.joinDate" class="flex-1" :full="true" :error="requestError.joinDate" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Office</div>
                        <ElementsSelectRef v-model="request.workPlaceSeq" placeholder="Select Office" class="flex-1 w-[26rem]" :full="true" :options="workPlaceOptions" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Department</div>
                        <ElementsSelectRef v-model="request.departmentSeq" placeholder="Select Department" class="flex-1 w-[26rem]" :full="true" :options="departmentOptions" :useDefaultMsg="true" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Tier</div>
                        <ElementsSelectRef v-model="request.jobTitleSeq" placeholder="Select Tier" class="flex-1 w-[26rem]" :full="true" :options="jobTitleOptions" :useDefaultMsg="true" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Work Schedule</div>
                        <ElementsSelectRef
                            v-model="request.workScheduleSeq"
                            placeholder="Select Work Schedule"
                            class="flex-1 w-[26rem]"
                            :full="true"
                            :options="workScheduleOptions"
                            :error="requestError.workScheduleSeq" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Payment Type<span>*</span></div>
                        <ElementsSelectRef
                            v-model="request.salaryAmountType"
                            placeholder="Select Payment Type"
                            class="flex-1 w-[26rem]"
                            :full="true"
                            :options="salaryAmountTypeOptions"
                            :error="requestError.salaryAmountType" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Basic Salary Amount<span>*</span></div>
                        <ElementsInput
                            v-model="request.salaryAmount"
                            placeholder="Enter Basic Salary Amount"
                            class="flex-1"
                            :full="true"
                            :maxlength="20"
                            :error="requestError.salaryAmount"
                            @afterInput="checkDecimalNumber" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Tax Code<span>*</span></div>
                        <ElementsSelectRef v-model="request.taxCode" placeholder="Select Tax Code" class="flex-1 w-[26rem]" :full="true" :options="taxCodeOptions" :error="requestError.taxCode" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Tin Number</div>
                        <ElementsInput
                            v-model="formattedTin"
                            placeholder="000-000-000-000"
                            class="flex-1"
                            :full="true"
                            :maxlength="15"
                            :error="requestError.tinNo"
                            @input="changeTinNumberFormat($event.target.value)" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">SSS No.</div>
                        <ElementsInput
                            v-model="formattedSSS"
                            placeholder="00-0000000-0"
                            class="flex-1"
                            :full="true"
                            :maxlength="12"
                            :error="requestError.socialSecuritySystem"
                            @input="changeSSSNumberFormat($event.target.value)" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">PHIL-HEALTH No.</div>
                        <ElementsInput
                            v-model="formattedPhilhealth"
                            placeholder="00-000000000-0"
                            class="flex-1"
                            :full="true"
                            :maxlength="14"
                            :error="requestError.philhealth"
                            @input="changePhilhealthFormat($event.target.value)" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">PAG-IBIG No.</div>
                        <ElementsInput
                            v-model="formattedPagIbig"
                            placeholder="0000-0000-0000"
                            class="flex-1"
                            :full="true"
                            :maxlength="14"
                            :error="requestError.pagIbig"
                            @input="changePagIbigFormat($event.target.value)" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">RDO Code</div>
                        <ElementsInput v-model="request.rdoCode" placeholder="Enter RDO Code" class="flex-1" :full="true" :maxlength="3" :error="requestError.rdoCode" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Bank Name</div>
                        <ElementsInput v-model="request.bankName" placeholder="Enter Bank Name" class="flex-1" :full="true" :maxlength="50" :error="requestError.bankName" />
                    </div>
                    <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Account Number</div>
                        <ElementsInput v-model="request.bankAccountNumber" placeholder="Enter Account Number" class="flex-1" :full="true" :maxlength="50" :error="requestError.bankAccountNumber" />
                    </div>
                </div>
                <div class="sticky bottom-0">
                    <div class="w-full h-px bg-gray-200"></div>
                    <div class="px-4 py-6 flex justify-end w-full bg-white">
                        <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="cancelInvite" />
                        <ElementsButton class="ml-2" text="Invite" v-if="!isEdit" :width28="true" :height12="true" @click="inviteEmployee" />
                        <ElementsButton class="ml-2" text="Resend" v-if="isEdit" :width28="true" :height12="true" @click="editAndResendEmployee" />
                    </div>
                </div>
            </div>
        </div>
        <ElementsNotification v-model="showErrorNotice" :notificationMsg="notificationErrMSG" :warning="true" />
    </div>
</template>
